<template>
  <section class="section-profile logsPage">
    <h2 class="section-profile__title">
      Логи и доступы
    </h2>
    <div
      v-if="GET_SELECT_REST"
      class="logsPage__body"
    >
      <h2 class="section-profile__title">
        {{ GET_SELECT_REST.name }} ({{ GET_SELECT_REST.code }})
      </h2>
      <div class="logsPage__top">
        <!-- <div class="logsPage__search">
          <vSearch @onHandler="search" />
        </div>
        <date-picker
          v-model:value="filterDate"
          :lang="langString"
          :disabled-date="disabledAfterToday"
          @change="selectDate"
          @clear="clearDate"
        /> -->
        <div class="logsPage__top-buttons">
          <div class="logsPage__top-btn">
            <vButton @click="clearCacheTables">
              Очистить кеш столов
            </vButton>
          </div>
          <div class="logsPage__top-btn">
            <vButton @click="clearCacheSite">
              Очистить общий кеш сервиса
            </vButton>
          </div>
          <div
            v-if="GET_SELECT_REST && GET_SELECT_REST.platforms_id === 2"
            class="logsPage__top-btn"
          >
            <vButton @click="getIdRest">
              Получить ID ресторана
            </vButton>
            <div
              v-show="errorID"
              class="errorText"
            >
              Нет активного ресторана
            </div>
          </div>
        </div>
      </div>
      <div class="logsPage-tabs">
        <div class="logsPage-tabs__header">
          <div
            v-for="tabBtn in tabs.tabsElements"
            :key="tabBtn.id"
            class="logsPage-tabs__btn"
            :class="{ active: tabs.activeTab === tabBtn.id }"
            @click="selectTab(tabBtn.id)"
          >
            {{ tabBtn.label }}
          </div>
        </div>
        <ul class="logsPage-tabs__list">
          <li
            v-if="tabs.activeTab === 2"
            class="logsPage-tabs__item"
          >
            <vLogsForm
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>

          <li
            v-if="tabs.activeTab === 3"
            class="logsPage-tabs__item"
          >
            <vLogsLicenseRkeeper
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>
          <li
            v-if="tabs.activeTab === 4"
            class="logsPage-tabs__item"
          >
            <vLogsPaySystems
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
              :pay-system="paySystem"
              @changePay="changePay"
              @savePayPlatform="savePayPlatform"
            />
          </li>
          <li
            v-if="tabs.activeTab === 6"
            class="logsPage-tabs__item"
          >
            <vLogsAcquiring
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>

          <li v-if="tabs.activeTab === 7">
            <vLoaderUpload
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>

          <li
            v-if="tabs.activeTab === 8"
            class="logsPage-tabs__item"
          >
            <vLogsStatistic
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>
          <li
            v-if="tabs.activeTab === 9"
            class="logsPage-tabs__item"
          >
            <vLogsTips
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
              :selected-rest="selectedRest"
              @chageDataSelectedRest="chageDataSelectedRest"
            />
          </li>
          <li
            v-if="tabs.activeTab === 10"
            class="logsPage-tabs__item"
          >
            <vLogsCastomization
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
              :code="GET_SELECT_REST.code"
            />
          </li>
          <li
            v-if="tabs.activeTab === 11"
            class="logsPage-tabs__item"
          >
            <VSectionWaiter
              :rest-id="GET_SELECT_REST.id"
              is-tech-support
            />
          </li>
          <li
            v-if="tabs.activeTab === 12"
            class="logsPage-tabs__item"
          >
            <vLogsTables
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>
          <li
            v-if="tabs.activeTab === 13"
            class="logsPage-tabs__item"
          >
            <vLogsLoyalty
              :id="GET_SELECT_REST.id"
              :token="GET_AUTHENTIFICATED"
            />
          </li>
        </ul>
      </div>
    </div>
    <vPreloader v-if="PRELOADER" />
    <vLightPreloader v-show="loading" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLogsForm from '@/components/logs/v-logs-form'
import vLogsLicenseRkeeper from '@/components/logs/v-logs-license-r-keeper'
import vLogsPaySystems from '@/components/logs/v-logs-pay-systems'
import vLogsAcquiring from '@/components/logs/v-logs-acquiring'
import vLogsStatistic from '@/components/logs/v-logs-statistic'
import vLogsTips from '@/components/logs/v-logs-tips'
import vLogsLoyalty from '@/components/logs/v-logs-loyalty'
import vLogsTables from '@/components/logs/v-logs-tables'
import vLogsCastomization from '@/components/logs/v-logs-castomization'
import vPreloader from '@/components/v-preloader'
import vLightPreloader from '@/components/v-light-preloader'
import vLoaderUpload from '@/components/profile/v-loader-upload'
import parseLogsFields from '@/utils/parseLogsFields'
import vSearch from '@/components/v-search'
import DatePicker from 'vue-datepicker-next'
import vButton from '@/components/v-button'
import 'vue-datepicker-next/index.css'
import '@/utils/localeDatePicker'
import VSectionWaiter from '@/views/pages/profile/waiter'
export default {
  name: 'Logs',
  components: {
    VSectionWaiter,
    // DatePicker,
    vPreloader,
    vLightPreloader,
    vLogsLicenseRkeeper,
    vLogsAcquiring,
    // vSearch,
    vButton,
    vLogsPaySystems,
    vLogsForm,
    vLoaderUpload,
    vLogsStatistic,
    vLogsTips,
    vLogsCastomization,
    vLogsTables,
    vLogsLoyalty,
  },
  data() {
    return {
      selectedRest: null,
      errorID: false,
      menu: {
        resize: true,
        activeRestarurant: 0,
      },
      tabs: {
        activeTab: 2,
        tabsElements: [
          // { id: 1, label: 'Логи' },
          { id: 2, label: 'Доступы' },
          { id: 3, label: 'Лицензия R-keeper' },
          { id: 4, label: 'r-keeper/iiko' },
          // { id: 5, label: 'Операции' },
          { id: 6, label: 'Эквайринг' },
          { id: 7, label: 'Лоадер' },
          { id: 8, label: 'Статистика' },
          { id: 9, label: 'Чаевые' },
          { id: 10, label: 'Кастомизация' },
          { id: 11, label: 'Официанты' },
          { id: 12, label: 'Столы' },
          { id: 13, label: 'Система лояльности' },
        ],
      },
      loading: false,
      logsData: {},
      page: 1,
      searchText: '',
      filterDate: '',
      convertedDate: '',
    }
  },
  computed: {
    ...mapGetters('support', ['ERROR', 'RKEEPER_DATA', 'GET_PLATFORMS_DATA']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    ...mapGetters('restaurant', [
      'GET_RESTAURANTS',
      'GET_RESTAURANTS_PAGES',
      'GET_SELECT_REST',
    ]),
    ...mapGetters('logs', ['LOGS_DATA', 'LICENSE_DATA']),
    ...mapGetters('preloader', ['PRELOADER']),
    parseActiveRest() {
      const filterData = this.GET_RESTAURANTS.filter(
        (i) => i.id === this.menu.activeRestarurant
      )
      return filterData[0]?.name
    },
  },
  watch: {
    async GET_SELECT_REST() {
      this.toggleLightPreloader()
      // await this.loadLogs()
      this.tabs.activeTab = 2
      setTimeout(() => {
        this.toggleLightPreloader()
      }, 500)
    },
  },
  async mounted() {
    this.TOGGLE_PRELOADER(true)
    await this.FETCH_RESTAURANTS({
      token: this.GET_AUTHENTIFICATED,
      search: '',
    })

    if (!this.GET_SELECT_REST?.id) {
      this.SELECT_REST(this.GET_RESTAURANTS[0])
    }

    // await this.loadLogs()
    this.selectedRest = this.GET_SELECT_REST
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('authentication', ['CHAANGE_STATUS']),
    ...mapActions('acquiring', ['GET_ID_IIKO']),
    ...mapActions('restaurant', ['SELECT_REST', 'FETCH_RESTAURANTS']),
    ...mapActions('logs', [
      'FETCH_LOGS',
      'UPDATE_ID_LICENSE',
      'FETCH_LICENSE',
      'CLEAR_CACHE_TABLES',
      'CLEAR_CACHE_ALL',
    ]),

    chageDataSelectedRest(newData) {
      this.selectedRest = newData
    },

    // Загрузка логов
    async loadLogs() {
      await this.FETCH_LOGS({
        page: 1,
        shops_id: this.GET_SELECT_REST.id,
        token: this.GET_AUTHENTIFICATED,
        text: this.searchText,
        date: this.filterDate,
      })
      if (Object.keys(this.LOGS_DATA).length) {
        this.logsData = parseLogsFields(this.LOGS_DATA)
      } else {
        this.logsData = {}
      }
    },

    async getIdRest() {
      this.TOGGLE_PRELOADER(true)
      this.errorID = false
      const response = await this.GET_ID_IIKO({
        id: this.GET_SELECT_REST.id,
        token: this.GET_AUTHENTIFICATED,
      })
      const [selectRest] = this.GET_RESTAURANTS.filter(
        (i) => i.id === this.GET_SELECT_REST.id
      )
      this.SELECT_REST(selectRest)
      this.TOGGLE_PRELOADER(false)
      if (response) {
        this.CHAANGE_STATUS({
          status: true,
          msg: 'ID ресторана получен',
        })
        setTimeout(() => {
          this.CHAANGE_STATUS({
            status: false,
            msg: '',
          })
        }, 3000)
      } else {
        this.errorID = true
        setTimeout(() => {
          this.errorID = false
        }, 3000)
      }
    },

    async selectDate() {
      if (this.filterDate) {
        const month = '' + (this.filterDate.getMonth() + 1),
          day = '' + this.filterDate.getDate(),
          year = this.filterDate.getFullYear()
        this.convertedDate = `${year}-${month}-${day}`
      } else {
        this.convertedDate = ''
      }
      await this.loadLogs()
      this.toggleLightPreloader()
    },

    selectTab(tab) {
      this.tabs.activeTab = tab
    },

    parseIp(data) {
      const arr = data.split('/')
      const arr2 = arr[2].split(':')
      this.ip = arr2[0]
      this.port = arr2[1]
    },

    toggleLightPreloader() {
      this.loading = !this.loading
    },

    async clearCacheSite() {
      this.TOGGLE_PRELOADER(true)
      const res = await this.CLEAR_CACHE_ALL({
        token: this.GET_AUTHENTIFICATED,
      })
      this.TOGGLE_PRELOADER(false)
      if (res) {
        this.CHAANGE_STATUS({
          status: true,
          msg: 'Общий кеш сервиса успешно очищен!',
        })
        setTimeout(() => {
          this.CHAANGE_STATUS({
            status: false,
            msg: '',
          })
        }, 3000)
      }
    },

    async clearCacheTables() {
      this.TOGGLE_PRELOADER(true)
      const res = await this.CLEAR_CACHE_TABLES({
        id: this.GET_SELECT_REST.id.toString(),
        token: this.GET_AUTHENTIFICATED,
      })
      this.TOGGLE_PRELOADER(false)
      if (res) {
        this.CHAANGE_STATUS({
          status: true,
          msg: res.message,
        })
        setTimeout(() => {
          this.CHAANGE_STATUS({
            status: false,
            msg: '',
          })
        }, 3000)
      }
    },

    async pagFunction(Number) {
      this.toggleLightPreloader()
      await this.FETCH_LOGS({
        page: Number,
        text: this.searchText,
        shops_id: this.menu.activeRestarurant,
        token: this.GET_AUTHENTIFICATED,
        date: this.convertedDate,
      })
      this.logsData = parseLogsFields(this.LOGS_DATA)
      this.toggleLightPreloader()
    },
    async search(text) {
      this.page = 1
      this.searchText = text
      await this.FETCH_LOGS({
        page: 1,
        text,
        shops_id: this.menu.activeRestarurant,
        token: this.GET_AUTHENTIFICATED,
        date: this.convertedDate,
      })
      this.logsData = parseLogsFields(this.LOGS_DATA)
    },
    async onClickPaination(num) {
      await this.FETCH_LOGS({ page: num, text: this.searchText })
      this.logsData = parseLogsFields(this.LOGS_DATA)
    },
  },
}
</script>

<style lang="scss">
.logsPage {
  & * {
    user-select: text;
    font-family: 'Commissioner';
  }
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  max-width: none;
  min-height: 100vh;
  border-radius: 0;
  margin: 0;
  display: block;
  background: #f8f9fd;
  @media (max-width: 769px) {
    padding-left: 0;
    background: #fff;
  }
  &__top {
    background: #fff;
    padding: 20px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid #e6e7eb;
    flex-wrap: wrap;
  }
  &__top-buttons {
    width: 100%;
    margin-top: 25px;
  }
  &__top-btn {
    width: 300px;
  }
  &-tabs {
    width: 100%;
    &__header {
      display: flex;
    }
    &__btn {
      padding: 10px;
      margin-bottom: -1px;
      margin-left: -1px;
      min-width: 100px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #e6e7eb;
      background: #fff;
      &:first-child {
        border-radius: 11px 0 0 0;
      }
      &:last-child {
        border-radius: 0 11px 0 0;
      }
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
      }
      &:first-child {
        margin-left: 0;
        cursor: pointer;
      }
    }
    &__list {
      width: 100%;
      overflow: auto;
      background: #fff;
      padding: 20px;
      border: 1px solid #e6e7eb;
      margin: 0;
      border-radius: 0 11px 11px 11px;
    }
    &__item {
      width: 100%;
      overflow: auto;
      height: calc(100vh - 375px);
      padding-bottom: 30px;
      &--noscroll {
        min-height: calc(100vh - 375px);
        height: auto;
      }
    }
  }
  // &__body {
  //   padding: 0 30px;
  // }
  &__search {
    width: 30%;
  }
}

.Pagination {
  display: flex;
  align-items: center;
  li {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    color: #343d5d;
    font-weight: 500;
    font-size: 12px;
    &:hover {
      background: #e6e7eb !important;
    }
    &.Page-active {
      background: #e6e7eb !important;
    }
  }
  .DotsHolder {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    justify-content: center;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .PaginationControl {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 24px;
    }
  }
}
.errorText {
  padding-left: 12px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ec4e4e;
}
</style>
