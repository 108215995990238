<template>
  <div class="vLogsAcquiring">
    <vLightPreloader
      v-show="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div
      v-if="GET_ACQUIRIG_ALERT || GET_ALERT"
      style="margin-bottom: 15px"
    >
      {{ GET_ACQUIRIG_ALERT || GET_ALERT }}
    </div>
    <div class="vLogsAcquiring__list">
      <vSelect
        :values="platforms"
        :data="platformName"
        :outside-click-data="outsideClickData"
        element="platform"
        placeholder="Эквайринг"
        is-acquiring
        @selectValue="changePlatform"
      />
    </div>

    <vSelect
      key="cur2"
      :values="currencyTypes"
      :data="currencyTypeName"
      :outside-click-data="outsideClickData"
      element="currency"
      placeholder="Валюта"
      @selectValue="selectCurrency"
    />
    <div class="vLogsAcquiring__form">
      <!-- Best2pay -->
      <template v-if="selectPlatform.name === 'Best2pay'">
        <vInput
          id="Best2pay"
          v-model="Best2pay.accNumber"
          :value="Best2pay.accNumber"
          :required="true"
          :rule="'min:2'"
          placeholder="acc number"
          @input="hasChanges = true"
        />
        <vSelect
          :values="taxAll"
          :data="Best2pay.nds.name"
          :outside-click-data="outsideClickData"
          element="Best2pay"
          placeholder="НДС"
          @selectValue="selectValue"
        />
        <vSelect
          :values="taxations"
          :data="Best2pay.taxations.name"
          :outside-click-data="outsideClickData"
          element="Best2pay"
          placeholder="Систем налогообложения"
          @selectValue="selectTaxations"
        />
        <vButton
          type="submit"
          :disabled="
            !Best2pay.accNumber || !Best2pay.accNumber.length || !hasChanges
          "
          @click="toggleConfirmModal"
        >
          Сохранить
        </vButton>
        <div v-if="error">
          Ошибка сохранения данных
        </div>
      </template>
      <template v-else-if="selectPlatform.name === 'BEST2PAY_BARREL'">
        <vInput
          id="BEST2PAY_BARREL"
          v-model="BEST2PAY_BARREL.accNumber"
          :value="BEST2PAY_BARREL.accNumber"
          :required="true"
          :rule="'min:2'"
          placeholder="acc number"
          @input="hasChanges = true"
        />
        <vSelect
          :values="taxAll"
          :data="BEST2PAY_BARREL.nds.name"
          :outside-click-data="outsideClickData"
          element="BEST2PAY_BARREL"
          placeholder="НДС"
          @selectValue="selectValue"
        />
        <vSelect
          :values="taxations"
          :data="BEST2PAY_BARREL.taxations.name"
          :outside-click-data="outsideClickData"
          element="BEST2PAY_BARREL"
          placeholder="Систем налогообложения"
          @selectValue="selectTaxations"
        />
        <vButton
          type="submit"
          :disabled="
            !BEST2PAY_BARREL.accNumber ||
              !BEST2PAY_BARREL.accNumber.length ||
              !hasChanges
          "
          @click="toggleConfirmModal"
        >
          Сохранить
        </vButton>
        <div v-if="error">
          Ошибка сохранения данных
        </div>
      </template>

      <template v-else-if="selectPlatform.name === 'CloudPayments'">
        <vInput
          id="CloudPayments"
          v-model="CloudPayments.public_id"
          :value="CloudPayments.public_id"
          :required="true"
          :rule="'min:2'"
          placeholder="public id"
          @input="hasChanges = true"
        />
        <vButton
          :disabled="disabledCloudButton"
          @click="onVModalCloud"
        >
          Сохранить
        </vButton>
        <div v-if="error">
          Ошибка сохранения данных
        </div>
        <vModalCloud
          v-if="vModalCloud === true"
          @checkButton="checkButton"
          @onVModalCloud="onVModalCloud"
        />
      </template>
      <template v-else>
        <vButton
          type="submit"
          :disabled="!hasChanges"
          @click="toggleConfirmModal"
        >
          Сохранить
        </vButton>
      </template>
    </div>

    <!--    КАРТОЧКА КОМПАНИИ-->
    <div>
      <vInput
        id="nameOrganization"
        v-model="objectInput.nameOrganization"
        :value="objectInput.nameOrganization"
        type="text"
        :required="true"
        placeholder="Полное наименование организации"
      />
      <vInput
        id="inn"
        v-model="objectInput.inn"
        :value="objectInput.inn"
        placeholder="ИНН"
        type="number"
        :required="true"
        rules="min:10|max:12"
        mask="############"
      />
      <vInput
        id="phone"
        v-model="objectInput.phone"
        :value="objectInput.phone"
        placeholder="Телефон"
        :required="true"
        rules="phone"
      />
      <vButton
        type="submit"
        :disabled="
          requisitesDisabled ||
            !objectInput.nameOrganization.length ||
            objectInput.inn.length < 9 ||
            objectInput.inn.length === 9 ||
            !objectInput.phone.length ||
            objectInput.phone.length < 17
        "
        @click="onModalChangingDetails"
      >
        Сохранить
      </vButton>
    </div>
    <!--    КАРТОЧКА КОМПАНИИ-->

    <VModalConfirmChanges
      v-if="confirmModal"
      @closeModal="toggleConfirmModal"
      @confirmChanges="checkForm"
    />
  </div>

  <VModalConfirmationChangesDetails
    v-if="modalChangingDetails"
    @savingDetailsInstitution="savingDetailsInstitution"
    @onModalChangingDetails="onModalChangingDetails"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import convertPhoneToFull from '@/utils/convertPhoneToFull'
import vButton from '@/components/v-button'
import vInput from '@/components/v-input'
import vSelect from '@/components/v-select'
// import { Form } from 'vee-validate'
import vLightPreloader from '@/components/v-light-preloader'
import vModalCloud from '@/components/modals/v-modal-cloud'
import VModalConfirmationChangesDetails from '@/components/modals/v-modal-confirmation-changes-details.vue'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes'
export default {
  name: 'VLogsPaySystems',
  components: {
    vButton,
    vInput,
    // Form,
    vSelect,
    vLightPreloader,
    vModalCloud,
    VModalConfirmChanges,
    VModalConfirmationChangesDetails,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      platforms: [],
      selectPlatform: {
        id: '',
        name: '',
      },
      taxations: [],
      taxAll: [],
      Best2pay: {
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      BEST2PAY_BARREL: {
        accNumber: '',
        taxations: {
          name: '',
          val: '',
        },
        nds: {
          name: '',
          val: '',
        },
      },
      CloudPayments: {
        public_id: '',
      },
      currencyTypes: [],
      currencyType: {
        name: null,
        id: null,
      },
      vModalCloud: false,
      test2: '',
      error: null,
      hasChanges: false,
      outsideClickData: null,
      confirmModal: false,
      modalChangingDetails: false,
      objectInput: {
        phone: '',
        inn: '',
        nameOrganization: '',
      },
      requisitesDisabled: true,
    }
  },
  computed: {
    ...mapGetters('acquiring', ['GET_ACQUIRIG', 'GET_ALERT', 'GET_DETAILS']),
    ...mapGetters('support', ['GET_ACQUIRIG_SUPPORT', 'GET_ACQUIRIG_ALERT']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('restaurant', ['GET_SELECT_REST']),
    currencyTypeName() {
      return this.currencyType?.name || ''
    },
    platformName() {
      return this.selectPlatform?.name || ''
    },
    disabledCloudButton() {
      if (this.CloudPayments.public_id) {
        return !this.CloudPayments.public_id.length && !this.hasChanges
      } else {
        return true
      }
    },
  },
  watch: {
    objectInput: {
      deep: true,
      handler() {
        this.requisitesDisabled = false
      },
    },
    selectPlatform: {
      deep: true,
      handler(selectPlatform) {
        const newTaxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i) => i.acquiring_id === this.selectPlatform.id
        )
        this.taxAll = newTaxAll

        const newTaxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i) => i.acquiring_id === this.selectPlatform.id
        )
        this.taxations = newTaxations
      },
    },
  },
  async mounted() {
    await this.gettingDetails()
    document.addEventListener('click', this.clickUotSide)
    try {
      this.isLoading = true
      await this.fetchAcquiringData()
    } catch (e) {
      console.error('error fetchAcquiringData', e)
    } finally {
      this.isLoading = false
    }

    const detailsInstitution = this.GET_DETAILS.filter(
      (i) => i.shops_id === this.GET_SELECT_REST.id
    )
    if (detailsInstitution.length) {
      this.objectInput.nameOrganization =
        detailsInstitution[0].name_organization
      this.objectInput.inn = detailsInstitution[0].inn
      this.objectInput.phone = this.filterPhone(detailsInstitution[0].phone)
      setTimeout(() => {
        this.requisitesDisabled = true
      }, 100)
    }
  },
  unmounted() {
    this.hasChanges = false
    document.removeEventListener('click', this.clickUotSide)
  },
  methods: {
    ...mapActions('acquiring', [
      'FETCH_ACQUIRIG',
      'SAVE_ACQUIRIG',
      'GETTING_DETAILS',
      'SAVING_DETAILS_INSTITUTION',
    ]),
    ...mapActions('support', [
      'FETCH_ACQUIRIG_SUPPORT',
      'SETUP_ACQUIRIG_SUPPORT',
    ]),

    onModalChangingDetails() {
      this.modalChangingDetails = !this.modalChangingDetails
    },

    gettingDetails() {
      this.GETTING_DETAILS({ token: this.token })
    },

    filterPhone(phone) {
      if (phone) {
        let ph = phone.replace(/\s/g, '')
        ph = ph.slice(1)
        return convertPhoneToFull(ph)
      }
      return ''
    },

    async savingDetailsInstitution() {
      this.isLoading = true
      const res = {
        id: this.id,
        name_organization: this.objectInput.nameOrganization,
        inn: this.objectInput.inn,
        phone: convertPhoneFormat(this.objectInput.phone),
        token: this.GET_AUTHENTIFICATED,
      }
      await this.SAVING_DETAILS_INSTITUTION(res)
      this.isLoading = false
    },

    clickUotSide(e) {
      this.outsideClickData = e.composedPath()
      // console.log(e.composedPath())
      // console.log(e.composedPath().includes(this.$refs.list))
    },
    async fetchAcquiringData() {
      const response = await this.FETCH_ACQUIRIG_SUPPORT({
        id: this.id,
        token: this.token,
      })
      this.platforms = this.GET_ACQUIRIG_SUPPORT
        ? this.GET_ACQUIRIG_SUPPORT.acquirings
        : []
      if (response) {
        await this.FETCH_ACQUIRIG({
          id: this.id,
          token: this.GET_AUTHENTIFICATED,
        })

        this.selectPlatform.name = this.GET_ACQUIRIG?.acquiring_code
        this.selectPlatform.id = this.GET_ACQUIRIG?.acquiring_id

        this[this.GET_ACQUIRIG?.acquiring_code] = {
          accNumber: '',
          nds: {
            name: null,
            val: null,
          },
          taxations: {
            name: null,
            val: null,
          },
        }

        this.taxAll = this.GET_ACQUIRIG_SUPPORT.tax_all.filter(
          (i) => i.acquiring_id === this.GET_ACQUIRIG.acquiring_id
        )
        this.taxations = this.GET_ACQUIRIG_SUPPORT.taxations.filter(
          (i) => i.acquiring_id === this.GET_ACQUIRIG.acquiring_id
        )

        if (
          this.GET_ACQUIRIG?.acquiring_code === 'Best2pay' ||
          this.GET_ACQUIRIG?.acquiring_code === 'BEST2PAY_BARREL'
        ) {
          if (this.GET_ACQUIRIG?.acc_number) {
            this[this.GET_ACQUIRIG?.acquiring_code]['accNumber'] =
              this.GET_ACQUIRIG?.acc_number
          }
          if (this.taxAll) {
            this[this.GET_ACQUIRIG?.acquiring_code]['nds']['name'] =
              this.GET_ACQUIRIG?.tax_name ?? ''
            this[this.GET_ACQUIRIG?.acquiring_code]['nds']['val'] =
              this.GET_ACQUIRIG?.tax_id ?? ''
          }
          if (this.taxations) {
            this[this.GET_ACQUIRIG?.acquiring_code]['taxations']['name'] =
              this.GET_ACQUIRIG?.taxation_system_name ?? ''
            this[this.GET_ACQUIRIG?.acquiring_code]['taxations']['val'] =
              this.GET_ACQUIRIG?.taxation_system_id ?? ''
          }
        } else {
          this.CloudPayments.public_id = this.GET_ACQUIRIG?.public_id ?? ''
        }
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyTypes
          ? this.GET_ACQUIRIG_SUPPORT.currencyTypes.map((e) => {
              return {
                name: e.writing,
                id: e.designation,
              }
            })
          : []

        const selectedCurrency = this.GET_ACQUIRIG?.currency_type
        if (selectedCurrency) {
          this.currencyType.name = this.GET_ACQUIRIG?.currency_writing
          this.currencyType.id = this.GET_ACQUIRIG?.currency_type
        }
      } else {
        this.taxAll = this.GET_ACQUIRIG.tax_all
        if (this.taxAll) {
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name]['nds'][
            'name'
          ] = this.GET_ACQUIRIG.acquiring_settings?.tax?.name
          this[this.GET_ACQUIRIG_SUPPORT.data?.acquiringSelected?.name]['nds'][
            'val'
          ] = this.GET_ACQUIRIG?.acquiring_settings?.tax?.id
        }
        this.currencyTypes = this.GET_ACQUIRIG_SUPPORT?.currencyType
          ? this.GET_ACQUIRIG_SUPPORT.currencyType.map((e) => {
              return {
                name: e.writing,
                id: e.designation,
              }
            })
          : []
      }
    },
    selectValue(data) {
      this.hasChanges = true
      this[data.element]['nds'] = { name: data.name, val: data.val }
    },
    selectTaxations(data) {
      this.hasChanges = true
      this[data.element]['taxations'] = { name: data.name, val: data.val }
    },
    selectCurrency(payload) {
      this.currencyType = payload
      this.hasChanges = true
    },
    async changePlatform(val) {
      this.selectPlatform.id = val.id
      this.selectPlatform.name = val.name

      this.hasChanges = true

      // try {
      //   if (this.isLoading) return
      //   this.isLoading = true
      //   this.selectPlatform.id = val.id
      //   this.selectPlatform.name = val.name
      //   await this.SETUP_ACQUIRIG_SUPPORT({
      //     id: this.id,
      //     id_shop: val.id,
      //     currency_type: this.currencyType.id,
      //     token: this.token,
      //   })
      //
      //   await this.fetchAcquiringData()
      // } catch (err) {
      //   console.log(err)
      // } finally {
      //   this.isLoading = false
      // }
    },
    async checkForm() {
      this.toggleConfirmModal()
      this.isLoading = true
      this.hasChanges = false

      const isB2p =
        this.selectPlatform.name === 'Best2pay' ||
        this.selectPlatform.name === 'BEST2PAY_BARREL'
      const isCloud = this.selectPlatform.name === 'CloudPayments'

      const getB2p =
        this.selectPlatform.name === 'Best2pay' ? 'Best2pay' : 'BEST2PAY_BARREL'

      const data = {
        acquiring_id: this.selectPlatform.id,
        shop_id: this.id,
        currency_type: this.currencyType.id,
        acc_number: isB2p ? this[getB2p].accNumber : null,
        public_id: isCloud ? this.CloudPayments.public_id : null,
        tax_id: isB2p ? this[getB2p].nds.val : null,
        taxation_system_id: isB2p ? this[getB2p].taxations.val : null,
        token: this.GET_AUTHENTIFICATED,
      }
      // await this.SETUP_ACQUIRIG_SUPPORT({
      //   id: this.id,
      //   id_shop: this.selectPlatform.id,
      //   currency_type: this.currencyType.id,
      //   token: this.token,
      // })
      await this.SAVE_ACQUIRIG(data)

      await this.FETCH_ACQUIRIG({
        id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      this.isLoading = false
    },
    async checkButton() {
      this.isLoading = true
      const data = {
        shop_id: this.id,
        public_id: this.CloudPayments.public_id,
        token: this.GET_AUTHENTIFICATED,
        currency_type: this.currencyType.id,
        acquiring_id: this.selectPlatform.id,
      }
      this.vModalCloud = !this.vModalCloud

      await this.SETUP_ACQUIRIG_SUPPORT({
        id: this.id,
        id_shop: this.selectPlatform.id,
        currency_type: this.currencyType.id,
        token: this.token,
      })
      await this.SAVE_ACQUIRIG(data)
      this.isLoading = false
    },
    onVModalCloud() {
      this.vModalCloud = !this.vModalCloud
      console.log('onVModalCloud')
    },

    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsAcquiring {
  position: relative;
  width: 100%;
  height: 100%;
  &__list {
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: 15px;
    }
  }
}
</style>
