<template>
  <div class="vRadioButton">
    <div class="vRadioButton__wrap">
      <input
        :id="id"
        type="radio"
        class="vRadioButton__input"
        :name="name"
        :checked="value == id"
        @input="onInput(id)"
      >
      <div class="vRadioButton__element" />
    </div>
    <label
      v-if="text"
      :for="id"
      class="vRadioButton__text"
    >{{ text }}</label>
  </div>
</template>

<script>
export default {
  name: 'VRadioButton',
  props: {
    name: {
      type: String,
      required: true,
      default: 'tapper',
    },
    id: {
      type: String,
      required: true,
      default: 'tapper',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Number],
      require: true,
      default: '',
    },
  },
  emits: ['update:modelValue', 'changeData'],
  methods: {
    onInput(val) {
      this.$emit('update:modelValue', val)
      this.$emit('changeData', val)
    },
  },
}
</script>

<style lang="scss">
.vRadioButton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &__wrap {
    position: relative;
    cursor: pointer;
  }
  &__input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 15px;
    height: 15px;
    opacity: 0;
    margin: 0;
    cursor: pointer;
    &:checked + * {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #6764ff;
        width: 58%;
        height: 58%;
        border-radius: 50%;
      }
    }
  }
  &__element {
    border: 1px solid #6764ff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
  }
  &__text {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
