<template>
  <div
    :ref="placeholder"
    v-click-outside="hideDrop"
    class="vSelect"
    :class="[{ active: drop }, { err: error }]"
  >
    <img
      src="~@/assets/images/arrow_select.svg"
      class="vSelect__arrow"
    >
    <div
      class="vSelect__placeholder"
      :class="{ active: data.length }"
    >
      {{ placeholder }}
    </div>
    <div
      class="vSelect__label"
      :class="{ '--empty-placeholder': !placeholder }"
      @click="toggleDrop"
    >
      {{ data ? data : '' }}
    </div>
    <ul
      v-show="drop"
      class="vSelect__list"
    >
      <li
        v-for="item in values"
        :key="item.id"
        class="vSelect__item"
        :class="{ active: item.name === data || item.id === data }"
        @click="selectValue(item.name, item.id)"
      >
        {{ item.name }}
      </li>
    </ul>

    <div
      v-if="error"
      class="vSelect__err"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    placeholder: {
      type: String,
      require: true,
      default: '',
    },
    element: {
      type: String,
      require: true,
      default: '',
    },
    values: {
      type: Array,
      required: true,
      default: () => [],
    },
    data: {
      type: String || Number,
      required: true,
      default: null,
    },
    outsideClickData: {
      type: Array,
      required: false,
      default: () => [],
    },
    error: {
      type: String,
      require: false,
      default: '',
    },
  },
  emits: ['selectValue'],
  data() {
    return {
      drop: false,
    }
  },
  watch: {
    outsideClickData() {
      const refData = this.placeholder
      if (!this.outsideClickData.includes(this.$refs[refData])) {
        this.drop = false
      }
    },
  },
  methods: {
    selectValue(name, id) {
      this.$emit('selectValue', {
        name: name,
        val: id,
        id: id,
        element: this.element,
      })
      this.drop = false
    },
    toggleDrop() {
      this.drop = !this.drop
    },
    hideDrop() {
      this.drop = false
    },
  },
}
</script>

<style lang="scss">
.vSelect {
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 52px;
  transition: background-color 0.3s;
  & * {
    user-select: none;
  }
  &.err {
    border: 1px solid #ec4e4e;
    background-color: #f8e0e0;
  }
  &__arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  &.active {
    border-color: #6764ff;
    .vSelect__arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__placeholder {
    position: absolute;
    top: 50%;
    left: 12px;
    font-weight: 500;
    font-size: 16px;
    color: #80869a;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 2;
    pointer-events: none;
    &.active {
      color: #80869a;
      font-weight: 400;
      font-size: 12px;
      position: absolute;
      left: 12px;
      top: 8px;
      transform: none;
    }
  }
  &__label {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #80869a;
    text-align: left;
    padding: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #010d35;
    font-weight: 500;
    font-size: 16px;
    padding-top: 18px;

    &.--empty-placeholder {
      padding-top: 0;
    }
  }
  &__list {
    position: absolute;
    left: -1px;
    top: 100%;
    background: #fff;
    z-index: 4;
    width: calc(100% + 2px);
    border-radius: 12px;
    padding: 6px 0;
    border: 1px solid #e6e7eb;
    box-shadow: 0px 4px 8px -2px rgba(1, 13, 53, 0.1),
      0px 2px 4px -2px rgba(1, 13, 53, 0.06);
    border-radius: 6px;
    margin: 8px 0 0 0;
    padding: 16px;
  }
  &__item {
    text-align: left;
    padding: 6px 12px;
    cursor: pointer;
    background: #f7f8fa;
    border: 1px solid #d5dbf1;
    border-radius: 7px;
    color: #80869a;
    font-size: 14px;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover,
    &.active {
      background: #f8f9fe;
      border: 1px solid #e6e7eb;
      color: #010d35;
    }
  }
  &__err {
    position: absolute;
    bottom: -22px;
    padding-left: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
  }
}
</style>
