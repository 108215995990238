<template>
  <div class="vModalConfirmChanges">
    <div class="vModalConfirmChanges__overlay" />
    <div class="vModalConfirmChanges__content">
      <p class="vModalConfirmChanges__title">
        Вы действительно хотите сохранить изменения?
      </p>

      <div class="vModalConfirmChanges__buttons">
        <div class="vModalConfirmChanges__cancelBtnWrapper">
          <button
            class="vModalConfirmChanges__cancelBtn"
            @click="$emit('closeModal')"
          >
            Отмена
          </button>
        </div>
        <VButton
          :fix-width="true"
          @handleClick="$emit('confirmChanges')"
        >
          Сохранить
        </VButton>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/v-button'
export default {
  name: 'VModalConfirmChanges',

  components: {
    VButton,
  },

  emits: ['closeModal', 'confirmChanges'],
}
</script>

<style lang="scss">
.vModalConfirmChanges {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dcdee3;
    opacity: 0.9;
    z-index: 1;
  }
  &__content {
    max-width: 652px;
    padding: 32px;
    border: 1px solid #e6e7eb;
    border-radius: 25px;
    background: #fff;
    z-index: 2;
  }
  &__title {
    max-width: 439px;
    margin: 0 auto;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  &__buttons {
    display: flex;
  }
  &__cancelBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-right: 16px;
  }
  &__cancelBtn {
    border: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #80869a;
    background-color: transparent;
    cursor: pointer;
  }
  .vButton.fixWidth {
    flex: 1;
    margin: 0;
  }
}
</style>
