const parseLogsFields = (data) => {
  console.log('data', data)

  let copyData = { ...data }

  if (copyData?.logs.length) {
    let copyArr = [...copyData.logs]

    console.log('copyArr', copyArr)

    copyArr.forEach((i, idx) => {
      let newField

      console.log('newField', newField)
      console.log('copyArr-2', copyArr)

      switch (i.query[0]) {
        case '[':
          newField = null
          if (JSON.parse(i.query).length) {
            newField = JSON.parse(i.query)[0]
          }
          if (newField) {
            const ky = Object.keys(newField)
            let parseField = ''
            ky.forEach((j) => {
              parseField +=
                j +
                ': ' +
                '<span style="opacity: .6; font-weight: 500;">' +
                newField[j] +
                '</span>' +
                '<br />'
            })
            copyArr[idx]['query'] = parseField
          }
          break
        case '{':
          newField = JSON.parse(i.query)
          const ky = Object.keys(newField)
          let parseField = ''
          ky.forEach((j) => {
            if (typeof newField[j] === 'object' && newField[j] !== null) {
              if (Object.keys(newField[j]).length < 1) {
                parseField += j + ': -' + '<br />'
              } else {
                parseField +=
                  j +
                  ': ' +
                  '<span style="opacity: .6; font-weight: 500;">' +
                  newField[j] +
                  '</span>' +
                  '<br />'
              }
            }
          })
          copyArr[idx]['query'] = parseField
          break
        default:
          break
      }

      if (i.response) {
        switch (i.response[0]) {
          case '[':
            newField = null
            if (JSON.parse(i.response).length) {
              newField = JSON.parse(i.response)[0]
            }
            if (newField) {
              const ky = Object.keys(newField)
              let parseField = ''
              ky.forEach((j) => {
                parseField +=
                  j +
                  ': ' +
                  '<span style="opacity: .6; font-weight: 500;">' +
                  newField[j] +
                  '</span>' +
                  '<br />'
              })
              copyArr[idx]['response'] = parseField
            }
            break
          case '{':
            newField = JSON.parse(i.response)
            const ky = Object.keys(newField)
            let parseField = ''
            ky.forEach((j) => {
              if (newField[j] !== null) {
                parseField +=
                  j +
                  ': ' +
                  '<span style="opacity: .6; font-weight: 500;">' +
                  newField[j] +
                  '</span>' +
                  '<br />'
              }
            })

            copyArr[idx]['response'] = parseField
            break
          default:
            break
        }
      }
    })
  }

  return copyData
}

export default parseLogsFields
