<template>
  <div class="vLogsTables">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div v-if="!detail">
      <div class="vLogsTables__title">
        Список столиков
      </div>
      <div
        v-if="Array.isArray(GET_TABLES) && !GET_TABLES.length"
        class="vSectionQr__empty"
      >
        Не удалось получить список столов
      </div>
      <div
        v-else
        class="vLogsTables__header"
      >
        Поиск столов
        <div class="vSectionQr__search">
          <div class="vSectionQr__search-wrap">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7064_211293)">
                <path
                  d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7064_211293">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            <input
              v-model="range_from"
              v-maska="'######'"
              type="text"
              class="vSectionQr__input"
              placeholder="С:"
            >
          </div>

          <div class="vSectionQr__search-wrap">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7064_211293)">
                <path
                  d="M19 19L15.71 15.71M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382V6.8382Z"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7064_211293">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            <input
              v-model="range_to"
              v-maska="'######'"
              type="text"
              class="vSectionQr__input"
              placeholder="По:"
            >
          </div>
          <button
            type="button"
            class="vSectionQr__btn"
            @click="search"
          >
            Найти
          </button>
          <button
            v-show="reset"
            type="button"
            class="vSectionQr__btn vSectionQr__btn--red"
            @click="resetSerch"
          >
            Сбросить
          </button>
        </div>
      </div>

      <div
        v-if="GET_TABLES.tables && !GET_TABLES.tables.length"
        class="vSectionQr__empty"
      >
        Ничего не найдено, попробуйте изменить параметры
      </div>

      <div
        v-else
        class="vSectionQr__list-wrap"
      >
        <ul class="vSectionQr__list">
          <li
            v-for="table in firstRowTables"
            :key="table.id"
            class="vSectionQr__item"
            @click="onDetailTable(table)"
          >
            Стол {{ table.code }}
            <span
              v-if="table.temporary_code"
              class="vSectionQr__code"
            >Код: {{ table.temporary_code }}</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16L14 12L10 8"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
        </ul>

        <ul class="vSectionQr__list">
          <li
            v-for="table in secondRowTables"
            :key="table.id"
            class="vSectionQr__item"
            @click="onDetailTable(table)"
          >
            Стол {{ table.code }}
            <span
              v-if="table.temporary_code"
              class="vSectionQr__code"
            >Код: {{ table.temporary_code }}</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16L14 12L10 8"
                stroke="#80869A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
        </ul>
      </div>
      <vPargnation
        v-if="GET_TABLES.tables && GET_TABLES.tables.length"
        :pages="GET_TABLES.countPage"
        :page="GET_TABLES.pageCurrent"
        @onHandler="onClickPagination"
      />
    </div>
    <div v-if="detail">
      <vSectionQrDetail
        :table-id="tableId"
        :shop-id="id.toString()"
        @onDetail="onDetail"
        @togglePreloader="togglePreloader"
      />
    </div>
    <div
      v-show="searchLoading"
      class="vSectionQr__preloader"
    >
      <img
        src="~@/assets/images/preloader.gif"
        alt="Загрузка..."
      >
      <div>Ищем столик</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vPargnation from '@/components/v-pagination.vue'
import vSectionQrDetail from '@/components/profile/v-section-qr-detail'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VLogsTables',
  components: {
    vLightPreloader,
    vPargnation,
    vSectionQrDetail,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      detail: false,
      range_from: '',
      range_to: '',
      reset: false,
      ableData: {},
      tableId: {},
      searchLoading: false,
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_TABLES', 'GET_QR']),
    firstRowTables() {
      return this.GET_TABLES && this.GET_TABLES.tables
        ? this.GET_TABLES.tables.slice(0, 5)
        : []
    },
    secondRowTables() {
      return this.GET_TABLES && this.GET_TABLES.tables
        ? this.GET_TABLES.tables.slice(5)
        : []
    },
  },
  async mounted() {
    await this.FETCH_TABLES({
      token: this.token,
      page: 1,
      shop_id: this.id,
    })
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapActions('restaurant', [
      'FETCH_TABLES',
      'DOWNLOAD_QR_CODE',
      'FETCH_SELECTED_TABLE',
    ]),
    togglePreloader(status) {
      this.isLoading = status
    },
    async resetSerch() {
      this.searchLoading = true
      await this.FETCH_TABLES({
        token: this.token,
        page: 1,
        shop_id: this.id,
      })
      this.range_from = ''
      this.range_to = ''
      this.reset = false
      this.searchLoading = false
    },
    async onClickPagination(num) {
      this.isLoading = true
      await this.FETCH_TABLES({
        page: num,
        range_from: this.range_from.length ? parseInt(this.range_from) : null,
        range_to: this.range_to.length ? parseInt(this.range_to) : null,
        token: this.token,
        shop_id: this.id,
      })
      this.isLoading = false
    },
    async search() {
      if (this.range_from.length || this.range_to.length) {
        this.searchLoading = true
        await this.FETCH_TABLES({
          token: this.token,
          page: 1,
          range_from: this.range_from.length ? parseInt(this.range_from) : null,
          range_to: this.range_to.length ? parseInt(this.range_to) : null,
          shop_id: this.id,
        })
        this.reset = true
        this.searchLoading = false
      }
    },
    onDetail() {
      this.detail = !this.detail
    },

    toggleId(table) {
      this.tableId = table.id
    },

    onDetailTable(table) {
      console.log('onDetailTable', table)
      this.onDetail()
      this.toggleId(table)
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsTables {
  position: relative;
  min-height: calc(100vh - 375px);
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 596px;
    width: 100%;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
}
</style>
