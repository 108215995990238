<template>
  <div class="vLogsCastomization">
    <div class="vLogsCastomization__title">
      Стилизация
    </div>
    <div class="vLogsCastomization__buttons">
      <vRadioButton
        id="TAPPER"
        text="Tapper"
        :value="customType"
        name="customType"
        @changeData="changetipsType"
      />
      <vRadioButton
        v-for="item in GET_CUSTOMIZATION_LIST"
        :id="item.code"
        :key="item.id"
        :text="item.name"
        :value="customType"
        name="customType"
        @changeData="changetipsType"
      />
    </div>
    <div class="vLogsCastomization__button">
      <vButton
        :disabled="!customType"
        @click="save"
      >
        Сохранить
      </vButton>
    </div>
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLightPreloader from '@/components/v-light-preloader'
import vRadioButton from '@/components/v-radio-button'
import vButton from '@/components/v-button'
export default {
  name: 'VLogsCastomization',
  components: {
    vLightPreloader,
    vRadioButton,
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    code: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      customType: 'TAPPER',
    }
  },
  computed: {
    ...mapGetters('customization', [
      'GET_CUSTOMIZATION_LIST',
      'GET_CUSTOMIZATION',
    ]),
  },
  async mounted() {
    await this.FETCH_CUSTOMIZATION_LIST(this.token)
    await this.FETCH_CUSTOMIZATION_BY_CODE({
      token: this.token,
      code: this.code,
    })
    this.customType = this.GET_CUSTOMIZATION
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapActions('customization', [
      'FETCH_CUSTOMIZATION_LIST',
      'FETCH_CUSTOMIZATION_BY_CODE',
      'UPDATE_CUSTOMIZATION',
    ]),
    changetipsType(val) {
      this.customType = val
    },
    async save() {
      this.isLoading = true
      let customizationId = null
      if (this.customType !== 'TAPPER') {
        const [res] = this.GET_CUSTOMIZATION_LIST.map((i) => {
          if (i.code === this.customType) {
            return i.id
          }
        })
        customizationId = res
      }
      await this.UPDATE_CUSTOMIZATION({
        token: this.token,
        shopId: this.id,
        customizationId,
      })
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsCastomization {
  position: relative;
  width: 100%;
  height: 100%;
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__buttons {
    display: flex;
    flex-direction: column;

    * {
      margin-bottom: 15px;
    }
  }
  &__button {
    max-width: 500px;
  }
}
</style>
