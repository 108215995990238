<template>
  <div class="container">
    <div class="actionsBar">
      <div class="fileInput">
        <input
          ref="file"
          type="file"
          accept=".gif"
          @change="fileUpload"
        >
        <div class="fileInput__label">
          <span v-if="!gif">Загрузить лоадер</span>
          <span v-else>Изменить лоадер</span>
        </div>
      </div>

      <!--      <vInput-->
      <!--        v-model="code_color"-->
      <!--        :value="code_color"-->
      <!--        placeholder="Код цвета фона HEX"-->
      <!--        @input="(event) => checkingForChanges('code_color', event.target.value)"-->
      <!--      />-->
      <!--      <vInput-->
      <!--        v-model="size"-->
      <!--        :value="size"-->
      <!--        placeholder="Размер"-->
      <!--        @input="(event) => checkingForChanges('size', event.target.value)"-->
      <!--      />-->
      <vButton
        type="button"
        :fix-width="true"
        :disabled="savedDisabled"
        @click="saveLoader"
      >
        Сохранить
      </vButton>
    </div>
    <div class="phoneBackground">
      <div
        class="previewContainer"
        :style="{ 'background-color': code_color }"
      >
        <div class="loaderContainer">
          <img
            ref="preview"
            :style="{ width: size, 'min-width': size }"
            :src="gif"
            class="preview"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import { mapGetters } from 'vuex'

export default {
  name: 'VLoaderUpload',
  components: {
    vButton,
    // vInput
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      size: '100%',
      code_color: '#fff',
      gif: '',
      savedDisabled: true,
    }
  },
  computed: {
    ...mapGetters('loader', ['GET_LOADER']),
  },

  watch: {
    GET_LOADER: function (val) {
      Object.keys(val).forEach((item) => {
        this[item] = val[item]
      })
      this.checkingForChanges()
    },
  },

  mounted() {
    this.fetchLoader()
  },

  methods: {
    fileUpload(e) {
      let preview = this.$refs.preview
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.onloadend = () => {
        const { result: base64 } = reader
        preview.src = base64
        this.gif = base64
      }

      if (file) {
        reader.readAsDataURL(file)
        this.savedDisabled = false
      } else {
        preview.src = ''
      }
    },
    saveLoader() {
      const data = {
        size: this.size,
        code_color: this.code_color,
        gif: this.gif,
      }
      this.$store.dispatch('loader/SAVE_LOADER', {
        data,
        merchantId: this.id,
        token: this.token,
      })
    },
    fetchLoader() {
      this.$store.dispatch('loader/FETCH_LOADER', {
        merchantId: this.id,
        token: this.token,
      })
    },
    checkingForChanges(name, val) {
      this.savedDisabled = val === this.GET_LOADER[name]
    },
  },
}
</script>

<style lang="scss">
.actionsBar {
  max-width: 400px;
}

.container {
  display: flex;
}

.previewContainer {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 30px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}
.phoneBackground {
  width: 396px;
  height: 800px;
  margin-left: 100px;
  background: url('~@/assets/images/previewContainer.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
}
.loaderContainer {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  width: 100%;
}

.fileInput {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 0;
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  input {
    position: absolute;
    opacity: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    left: 0;
    top: 0;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }
  }

  &__label {
    z-index: 1;
  }
}
</style>
