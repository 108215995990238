<template>
  <div class="vLogsForm">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div v-else>
      <div v-if="!GET_PLATFORMS_DATA.data">
        Платформа не привязана
      </div>
      <div v-else>
        <h3 style="margin-bottom: 15px">
          <img
            v-if="GET_PLATFORMS_DATA.data.name === 'iiko'"
            src="@/assets/images/logo-iiko.png"
            width="100"
          >
          <img
            v-if="GET_PLATFORMS_DATA.data.name === 'r-keeper'"
            src="@/assets/images/logo-r_keeper.png"
            width="100"
          >
        </h3>

        <!-- r-keeper -->
        <Form
          v-if="
            GET_PLATFORMS_DATA.data &&
              GET_PLATFORMS_DATA.data.name === 'r-keeper'
          "
          v-slot="{ errors }"
          class="section-profile__form"
          @submit="toggleConfirmModal"
        >
          <vInput
            v-for="item in rkeeper"
            :id="item.name"
            :key="item.id"
            v-model="item.value"
            :type="item.type"
            :value="item.value"
            :required="true"
            :rule="item.type === 'password' ? 'min:6' : 'min:1'"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          />

          <div class="activity_establishments">
            <label class="checkbox__conditions max-width_availability">
              <input
                v-model="checkingAvailability"
                type="checkbox"
                checked
                class="checkbox__conditions-check"
              >
              <p class="checkbox__conditions-text">
                Проверка на доступность кассы
              </p>
            </label>

            <label class="checkbox__conditions max-width_plug">
              <input
                v-model="plugCheckbox"
                type="checkbox"
                checked
                class="checkbox__conditions-check"
              >
              <p class="checkbox__conditions-text">Заглушка</p>
            </label>
          </div>

          <vButton
            type="submit"
            :disabled="Object.keys(errors).length > 0"
          >
            Сохранить
          </vButton>
          <div v-if="ERROR">
            Ошибка сохранения данных
          </div>
        </Form>

        <!-- iiko -->
        <Form
          v-if="
            GET_PLATFORMS_DATA.data && GET_PLATFORMS_DATA.data.name === 'iiko'
          "
          v-slot="{ errors }"
          class="section-profile__form"
          @submit="toggleConfirmModal"
        >
          <vInput
            v-for="item in iiko"
            :id="item.name"
            :key="item.id"
            v-model="item.value"
            :type="item.type"
            :value="item.value"
            :required="true"
            :rule="item.type === 'password' ? 'min:6' : 'min:1'"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          />

          <div class="activity_establishments">
            <label class="checkbox__conditions max-width_availability">
              <input
                v-model="checkingAvailability"
                type="checkbox"
                checked
                class="checkbox__conditions-check"
              >
              <p class="checkbox__conditions-text">
                Проверка на доступность кассы
              </p>
            </label>

            <label class="checkbox__conditions max-width_plug">
              <input
                v-model="plugCheckbox"
                type="checkbox"
                checked
                class="checkbox__conditions-check"
              >
              <p class="checkbox__conditions-text">Заглушка</p>
            </label>
          </div>

          <vButton
            type="submit"
            :disabled="Object.keys(errors).length > 0"
          >
            Сохранить
          </vButton>
          <div v-if="ERROR">
            Ошибка сохранения данных
          </div>
        </Form>
      </div>
    </div>
    <VModalConfirmChanges
      v-if="confirmModal"
      @closeModal="toggleConfirmModal"
      @confirmChanges="checkForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vLightPreloader from '@/components/v-light-preloader'
import VModalConfirmChanges from '@/components/modals/v-modal-confirm-changes'
export default {
  name: 'VLogsForm',
  components: {
    vLightPreloader,
    Form,
    vInput,
    vButton,
    VModalConfirmChanges,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      checkingAvailability: false,
      plugCheckbox: false,
      iiko: [
        { id: 1, name: 'id', type: 'text', placeholder: 'ID', disabled: true },
        { id: 2, name: 'ip', type: 'text', placeholder: 'IP' },
        { id: 3, name: 'port', type: 'text', placeholder: 'Порт' },
        { id: 4, name: 'login', type: 'text', placeholder: 'Логин' },
        { id: 5, name: 'password', type: 'password', placeholder: 'Пароль' },
        {
          id: 5,
          name: 'shops_id',
          type: 'text',
          placeholder: 'ID заведения',
          disabled: true,
        },
      ],
      rkeeper: [
        { id: 1, name: 'id', type: 'text', placeholder: 'ID', disabled: true },
        {
          id: 6,
          name: 'login',
          type: 'text',
          placeholder: 'Логин пользователя rkeeper',
        },
        {
          id: 2,
          name: 'password',
          type: 'text',
          placeholder: 'Пароль пользователя rkeeper',
        },
        {
          id: 3,
          name: 'login_license',
          type: 'text',
          placeholder: 'Логин для доступа лицензирования',
        },
        {
          id: 4,
          name: 'password_license',
          type: 'text',
          placeholder: 'Пароль для доступа лицензирования',
        },
        {
          id: 5,
          name: 'token_license',
          type: 'text',
          placeholder: 'Токен лицензии',
          disabled: true,
        },
        { id: 7, name: 'url', type: 'text', placeholder: 'IP адрес' },
        { id: 8, name: 'restCode', type: 'text', placeholder: 'Код ресторана' },
        {
          id: 9,
          name: 'productGUID',
          type: 'text',
          placeholder: 'ID лицензии',
        },
        {
          id: 10,
          name: 'license_instance',
          type: 'text',
          placeholder: 'Экземпляр лицензии',
        },
        {
          id: 11,
          name: 'id_currency',
          type: 'text',
          placeholder: 'Идентификатор валюты',
        },
        {
          id: 12,
          name: 'first_request',
          type: 'text',
          placeholder: 'Первый запрос',
        },
        {
          id: 13,
          name: 'id_manager',
          type: 'text',
          placeholder: 'Code менеджера',
        },
        {
          id: 14,
          name: 'id_station',
          type: 'text',
          placeholder: 'Code станции',
        },
        {
          id: 15,
          name: 'id_reason',
          type: 'text',
          placeholder: 'ID причины внесения предоплаты',
        },
        { id: 16, name: 'acc_number', type: 'text', placeholder: 'Acc' },
      ],
      confirmModal: false,
    }
  },
  computed: {
    ...mapGetters('support', [
      'ERROR',
      'GET_PLATFORMS_DATA',
      'RKEEPER_DATA',
      'GET_ACTIVITY_STATUS_DATA',
    ]),
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  async mounted() {
    await this.SHOP_ACTIVE({ token: this.token, id: this.id })
    if (this.GET_ACTIVITY_STATUS_DATA.check_active === 1) {
      this.checkingAvailability = true
    } else {
      this.checkingAvailability = false
    }

    if (this.GET_ACTIVITY_STATUS_DATA.active === 1) {
      this.plugCheckbox = false
    } else {
      this.plugCheckbox = true
    }

    await this.FETCH_PLATFORM({ token: this.token, id: this.id })
    if (
      this.GET_PLATFORMS_DATA.data &&
      this.GET_PLATFORMS_DATA.data.name === 'r-keeper'
    ) {
      await this.FETCH_DATA_RKEEPER({ token: this.token, id: this.id })
      Object.keys(this.rkeeper).forEach((i) => {
        const field = this.RKEEPER_DATA[this.rkeeper[i]['name']]
          ? this.RKEEPER_DATA[this.rkeeper[i]['name']]
          : ''
        this.rkeeper[i].value = field.toString()
      })
    }
    if (
      this.GET_PLATFORMS_DATA.data &&
      this.GET_PLATFORMS_DATA.data.name === 'iiko'
    ) {
      await this.FETCH_DATA_IIKO({ token: this.token, id: this.id })

      Object.keys(this.iiko).forEach((i) => {
        const field = this.RKEEPER_DATA[this.iiko[i]['name']]
          ? this.RKEEPER_DATA[this.iiko[i]['name']]
          : ''
        this.iiko[i].value = field.toString()
      })
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('support', [
      'FETCH_PLATFORM',
      'FETCH_DATA_RKEEPER',
      'SAVE_DATA_RKEEPER',
      'FETCH_DATA_IIKO',
      'SAVE_DATA_IIKO',
      'SHOP_ACTIVE',
      'SHOP_ACTIVE_CONSERVATION',
    ]),
    checkForm() {
      this.toggleConfirmModal()
      this.isLoading = true
      const req = {
        shops_id: this.id.toString(),
      }

      if (this.GET_PLATFORMS_DATA.data.name === 'r-keeper') {
        this.rkeeper.forEach((i) => {
          req[i.name] = i.value
        })
        this.SAVE_DATA_RKEEPER({ data: req, token: this.token })
      }

      if (this.GET_PLATFORMS_DATA.data.name === 'iiko') {
        this.iiko.forEach((i) => {
          req[i.name] = i.value
        })
        this.SAVE_DATA_IIKO({ data: req, token: this.token })
      }

      this.checkboxConditions()

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    checkboxConditions() {
      if (this.checkingAvailability) {
        this.check_active = 1
      } else {
        this.check_active = 0
      }
      if (this.plugCheckbox) {
        this.active = 0
      } else {
        this.active = 1
      }
      const req = {
        id: this.id.toString(),
        active: this.active,
        check_active: this.check_active,
      }
      this.SHOP_ACTIVE_CONSERVATION({ data: req, token: this.token })
    },

    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal
    },
  },
}
</script>

<style lang="scss">
.vLogsForm {
  position: relative;
  width: 100%;
  height: 100%;

  .checkbox__conditions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #80869a;

    &-check {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + .checkbox__conditions-text::before {
        background-image: url('~@/assets/images/paid_check.d789c5ba.svg');
      }
    }

    &-text {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
  }
}
.max-width_availability {
  max-width: 215px;
}
.max-width_plug {
  max-width: 90px;
}
</style>
