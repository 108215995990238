<template>
  <div class="vLogsLoyalty">
    <vLightPreloader
      v-if="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div class="vLogsLoyalty__title">
      Система лояльности
    </div>

    <div class="vLogsLoyalty__buttons">
      <vRadioButton
        id="OFF"
        text="Выкл"
        :value="active"
        name="active"
        @changeData="changeActive"
      />
      <vRadioButton
        id="NOVIKOV"
        text="Новиков"
        :value="active"
        name="active"
        @changeData="changeActive"
      />
    </div>
    <div class="vLogsLoyalty__button">
      <vButton
        :disabled="buttonDisabled"
        @click="save"
      >
        Сохранить
      </vButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vRadioButton from '@/components/v-radio-button'
import vLightPreloader from '@/components/v-light-preloader'
import vButton from '@/components/v-button'
export default {
  name: 'VLogsLoyalty',
  components: {
    vLightPreloader,
    vRadioButton,
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      active: 'OFF',
      buttonDisabled: true,
      isLoading: true,
    }
  },
  watch: {
    active() {
      this.buttonDisabled = false
    },
  },
  async mounted() {
    this.FETCH_LOYALTY({
      token: this.token,
      id: this.id,
    }).then((info) => {
      if (info) {
        this.active = info
      }
    })
    setTimeout(() => {
      this.buttonDisabled = true
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapActions('loyalty', ['FETCH_LOYALTY', 'SAVE_LOYALTY']),
    changeActive(val) {
      this.active = val
    },
    save() {
      this.isLoading = true
      this.SAVE_LOYALTY({
        token: this.token,
        id: this.id,
        loyalty: this.active,
      })

      setTimeout(() => {
        this.buttonDisabled = true
        this.isLoading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.vLogsLoyalty {
  position: relative;
  min-height: calc(100vh - 375px);

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    * {
      margin-bottom: 15px;
    }
  }

  &__button {
    max-width: 500px;
  }
}
</style>
